import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import {
  AuthUserContextProvider,
  FirebaseContextProvider,
  OngContextProvider,
  VendorContextProvider,
  CommissionContextProvider,
  WalletContextProvider,
  AnalyticsContextProvider,
  OfferContextProvider
} from './contexts';

import { Main } from './views';

import { ThemeProvider } from '@material-ui/styles';

import theme from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <FirebaseContextProvider>
        <AnalyticsContextProvider>
          <AuthUserContextProvider>
            <VendorContextProvider>
              <OfferContextProvider>
                <OngContextProvider>
                  <WalletContextProvider>
                    <CommissionContextProvider>
                      <BrowserRouter>
                        <Main />
                      </BrowserRouter>
                    </CommissionContextProvider>
                  </WalletContextProvider>
                </OngContextProvider>
              </OfferContextProvider>
            </VendorContextProvider>
          </AuthUserContextProvider>
        </AnalyticsContextProvider>
      </FirebaseContextProvider>
    </ThemeProvider>
  );
}

export default App;
