import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    company: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0.5)
    }
}))

export default useStyle;