import React, { createContext, useEffect, useReducer } from 'react';

import { firebase, firestore } from '../firebase';

import { walletReducer } from '../reducers/walletReducer';

import { FETCH_WALLET_SUCCESS, FETCH_WALLET_ERROR } from '../common/actionType';

export const WalletContext = createContext();

const initCommission = {
    personal: {
        count: 0,
        value: 0
    },
    referrals: {
        count: 0,
        value: 0
    }
}

const WalletContextProvider = (props) => {
    const [wallet, dispatch] = useReducer(walletReducer, {
        entry: {
            rate: 50,
            commission: {
                pending: initCommission,
                approved: initCommission,
                paid: initCommission,
                rejected: initCommission
            },
            paymentRequest: 0
        },
        error: null
    });

    useEffect(() => {
        const authListener = firebase.auth().onAuthStateChanged(authUser => {
            if (authUser) {
                firestore.collection("Wallets").doc(authUser.uid).onSnapshot(doc => {
                    dispatch({
                        type: FETCH_WALLET_SUCCESS,
                        entry: doc.data(),
                    });
                }, error => {
                    dispatch({
                            type: FETCH_WALLET_ERROR,
                            error: error,
                        });
                });
            }
        });
        return () => authListener();
    }, []);

    return (
        <WalletContext.Provider value={{ wallet, dispatch }}>
            {props.children}
        </WalletContext.Provider>

    );
}

export default WalletContextProvider;