import React, { useEffect } from 'react';

import { Link as LinkRouter, useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { makeStyles } from "@material-ui/styles";

import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Link
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import { SignIn } from '..';
import SignUp from '../Auth/SignUp/SignUp';
import { PRIVACY } from '../../common/routes';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyle = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    vendorsInfo: {
        marginRight: '2.875rem',
        marginLeft: '2.875rem'
    },
    img: {
        width: '75%',
        height: '50px'
    }
}));

const SignInDialog = (props) => {
    const { classes, openSignIn, handleCloseSignIn, handleSwitch } = props;
    return (
        <Dialog open={openSignIn} onClose={handleCloseSignIn} aria-labelledby="dialog-title-sign-in">
            <DialogTitle id="dialog-title-sign-in">
                {handleCloseSignIn ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseSignIn}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent>
                <SignIn handleSwitch={handleSwitch} />
            </DialogContent>
        </Dialog>
    )
}

const SignUpDialog = (props) => {
    const { classes, openSignUp, handleCloseSignUp, handleSwitch } = props;
    return (
        <Dialog open={openSignUp} onClose={handleCloseSignUp} aria-labelledby="dialog-title-sign-up">
            <DialogTitle id="dialog-title-sign-up">
                {handleCloseSignUp ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseSignUp}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent>
                <SignUp handleSwitch={handleSwitch} />
            </DialogContent>
        </Dialog>
    )
}

const vendors = [
    {
        label: "Elefant",
        imgPath: '/images/vendors/2performant-882.png',
    },
    {
        label: 'Evomag',
        imgPath: '/images/vendors/2performant-411.png',
    },
    {
        label: 'Fashion Days',
        imgPath: '/images/vendors/2performant-488.jpg',
    },
    {
        label: 'Vivre',
        imgPath: '/images/vendors/2performant-1507.jpg',
    },
    {
        label: 'Decathlon',
        imgPath: '/images/vendors/2performant-2754.jpg',
    },
    {
        label: 'Animax',
        imgPath: '/images/vendors/2performant-3385.png',
    },
    {
        label: 'Nichiduta',
        imgPath: '/images/vendors/2performant-929.jpg',
    },
    {
        label: 'Answear',
        imgPath: '/images/vendors/2performant-907.png',
    },
    {
        label: 'Notino',
        imgPath: '/images/vendors/2performant-3409.png',
    },
    {
        label: 'Vegis',
        imgPath: '/images/vendors/2performant-1961.png',
    },
    {
        label: 'Meli Melo Paris',
        imgPath: '/images/vendors/2performant-1275.jpg',
    },
    {
        label: 'Lensa',
        imgPath: '/images/vendors/2performant-1860.png',
    },
    {
        label: 'Dona Online',
        imgPath: '/images/vendors/2performant-955.jpg',
    },
    {
        label: 'Hiris',
        imgPath: '/images/vendors/2performant-2756.png',
    },
    {
        label: 'Libri',
        imgPath: '/images/vendors/2performant-628.png',
    },
    {
        label: 'Chicco',
        imgPath: '/images/vendors/2performant-1567.png',
    },
    {
        label: 'Carturesti',
        imgPath: '/images/vendors/2performant-1677.png',
    },
    {
        label: 'Dyfashion',
        imgPath: '/images/vendors/2performant-2405.png',
    },
    {
        label: 'Startshiners',
        imgPath: '/images/vendors/2performant-876.jpg',
    }
];
const Landing = () => {
    const [openSignIn, setOpenSignIn] = React.useState(false);
    const [openSignUp, setOpenSignUp] = React.useState(false);

    const [activeStep1, setActiveStep1] = React.useState(0);
    const [activeStep2, setActiveStep2] = React.useState(1);
    const [activeStep3, setActiveStep3] = React.useState(2);
    const [activeStep4, setActiveStep4] = React.useState(3);
    const [activeStep5, setActiveStep5] = React.useState(4);
    const [activeStep6, setActiveStep6] = React.useState(5);

    const classes = useStyle();

    const { code } = useParams();

    useEffect(() => {
        if (code) {
            localStorage.setItem('code', code);
            localStorage.setItem('time', Math.floor(Date.now() / 1000));
            sessionStorage.setItem('code', code);
        }
    }, [code])

    const handleClickOpenSignIn = () => {
        setOpenSignIn(true);
    };

    const handleCloseSignIn = () => {
        setOpenSignIn(false);
    };

    const handleClickOpenSignUp = () => {
        setOpenSignUp(true);
    };

    const handleCloseSignUp = () => {
        setOpenSignUp(false);
    };

    const handleSignInSignUp = () => {
        handleCloseSignIn();
        handleClickOpenSignUp();
    }

    const handleSignUpSignIn = () => {
        handleCloseSignUp();
        handleClickOpenSignIn();
    }

    const handleStepChange1 = step => {
        if (step === vendors.length - 5) {
            setActiveStep1(0);
        } else {
            setActiveStep1(step);
        }
    }
    const handleStepChange2 = step => {
        if (step === vendors.length - 4) {
            setActiveStep2(1);
        } else {
            setActiveStep2(step);
        }
    }
    const handleStepChange3 = step => {
        if (step === vendors.length - 3) {
            setActiveStep3(2);
        } else {
            setActiveStep3(step);
        }
    }
    const handleStepChange4 = step => {
        if (step === vendors.length - 2) {
            setActiveStep4(3);
        } else {
            setActiveStep4(step);
        }
    }
    const handleStepChange5 = step => {
        if (step === vendors.length - 1) {
            setActiveStep5(4);
        } else {
            setActiveStep5(step);
        }
    }
    const handleStepChange6 = step => {
        if (step === 0) {
            setActiveStep6(5);
        } else {
            setActiveStep6(step);
        }
    }

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">

                <a className="navbar-brand logo-image" href="#"><img src="/images/logo/true.jpg" alt="alternative" /></a>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-awesome fas fa-bars"></span>
                    <span className="navbar-toggler-awesome fas fa-times"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarsExampleDefault">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a className="nav-link page-scroll" href="#header">Acasa <span className="sr-only">(current)</span></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link page-scroll" href="#magazine">Magazine</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle page-scroll" href="#cashback" id="featuresDropdown" role="button" aria-haspopup="true" aria-expanded="false">Functionalitate</a>
                            <div className="dropdown-menu" aria-labelledby="featuresDropdown">
                                <a className="dropdown-item" href="#cashback"><span className="item-text">Cashback</span></a>
                                <div className="dropdown-items-divide-hr"></div>
                                <a className="dropdown-item" href="#tombola"><span className="item-text">Tombola</span></a>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle page-scroll" href="#footer" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">Despre</a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href="https://docs.google.com/document/d/13VsdMoQlooTpyONu3XVgoPDvzgOc51bAUTIdtBHMgvM/edit?usp=sharing" rel="noopener noreferrer" target='_blank'><span className="item-text">Termeni & Conditii</span></a>
                                <div className="dropdown-items-divide-hr"></div>
                                <LinkRouter className="dropdown-item" to={PRIVACY}><span className="item-text">Confidentialitate</span></LinkRouter>
                            </div>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" href='https://cashback.true.deals'>INCEPE AICI</Link>
                        </li>
                    </ul>
                    <span className="nav-item social-icons">
                        <span className="fa-stack">
                            <a href="https://www.facebook.com/recompensashoppingului/" rel="noopener noreferrer" target='_blank'>
                                <i className="fas fa-circle fa-stack-2x facebook"></i>
                                <i className="fab fa-facebook-f fa-stack-1x"></i>
                            </a>
                        </span>
                        <span className="fa-stack">
                            <a href="https://www.instagram.com/recompensashoppingului/" rel="noopener noreferrer" target='_blank'>
                                <i className="fas fa-circle fa-stack-2x instagram"></i>
                                <i className="fab fa-instagram fa-stack-1x"></i>
                            </a>
                        </span>
                    </span>
                </div>
            </nav>

            <SignInDialog handleCloseSignIn={handleCloseSignIn} openSignIn={openSignIn} classes={classes} handleSwitch={handleSignInSignUp} />
            <SignUpDialog handleCloseSignUp={handleCloseSignUp} openSignUp={openSignUp} classes={classes} handleSwitch={handleSignUpSignIn} />
            <header id="header" className="header">
                <div className="header-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="text-container">
                                    <h1><span className="turquoise">TRUE</span><br />Recompensa shopping-ului</h1>
                                    <p className="p-large">Stiai ca de fiecare data cand cumperi ceva online PIERZI pana la 21% din banii cheltuiti fara motiv? Cu TRUE acest lucru ia sfarsit!</p>
                                    <a className="btn-solid-lg" href='https://cashback.true.deals'>INCEPE AICI</a>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image-container">
                                    <img className="img-fluid" src="/images/design/homepage.svg" alt="alternative" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div id="magazine" className="shops">
                <Grid container>
                    <Grid item xs={12} className={classes.vendorsInfo}>
                        <h2>Peste 500 MAGAZINE partenere</h2>
                        <p className="p-heading p-large">Puteti gasi magazine din diverse categorii precum: Auto & Moto, Electronice, Fashion, Carti & Filme, Sport, Jucarii, Copii & Baby, Cadouri, Casa si Gradina, Ingrijire personala si multe altele </p>
                    </Grid>
                    <Grid item xs={12} className={classes.vendorsInfo}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
                                <AutoPlaySwipeableViews
                                    axis='x'
                                    index={activeStep1}
                                    onChangeIndex={handleStepChange1}
                                    enableMouseEvents
                                >
                                    {vendors.map((step, index) => (
                                        <div key={step.label}>
                                            {Math.abs(activeStep1 - index) <= 1 ? (
                                                <img className={classes.img} src={step.imgPath} alt={step.label} />
                                            ) : null}
                                        </div>
                                    ))}
                                </AutoPlaySwipeableViews>
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
                                <AutoPlaySwipeableViews
                                    axis='x'
                                    index={activeStep2}
                                    onChangeIndex={handleStepChange2}
                                    enableMouseEvents
                                >
                                    {vendors.map((step, index) => (
                                        <div key={step.label}>
                                            {Math.abs(activeStep2 - index) <= 2 ? (
                                                <img className={classes.img} src={step.imgPath} alt={step.label} />
                                            ) : null}
                                        </div>
                                    ))}
                                </AutoPlaySwipeableViews>
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
                                <AutoPlaySwipeableViews
                                    axis='x'
                                    index={activeStep3}
                                    onChangeIndex={handleStepChange3}
                                    enableMouseEvents
                                >
                                    {vendors.map((step, index) => (
                                        <div key={step.label}>
                                            {Math.abs(activeStep3 - index) <= 2 ? (
                                                <img className={classes.img} src={step.imgPath} alt={step.label} />
                                            ) : null}
                                        </div>
                                    ))}
                                </AutoPlaySwipeableViews>
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
                                <AutoPlaySwipeableViews
                                    axis='x'
                                    index={activeStep4}
                                    onChangeIndex={handleStepChange4}
                                    enableMouseEvents
                                >
                                    {vendors.map((step, index) => (
                                        <div key={step.label}>
                                            {Math.abs(activeStep4 - index) <= 2 ? (
                                                <img className={classes.img} src={step.imgPath} alt={step.label} />
                                            ) : null}
                                        </div>
                                    ))}
                                </AutoPlaySwipeableViews>
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
                                <AutoPlaySwipeableViews
                                    axis='x'
                                    index={activeStep5}
                                    onChangeIndex={handleStepChange5}
                                    enableMouseEvents
                                >
                                    {vendors.map((step, index) => (
                                        <div key={step.label}>
                                            {Math.abs(activeStep5 - index) <= 2 ? (
                                                <img className={classes.img} src={step.imgPath} alt={step.label} />
                                            ) : null}
                                        </div>
                                    ))}
                                </AutoPlaySwipeableViews>
                            </Grid>
                            <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
                                <AutoPlaySwipeableViews
                                    axis='x'
                                    index={activeStep6}
                                    onChangeIndex={handleStepChange6}
                                    enableMouseEvents
                                >
                                    {vendors.map((step, index) => (
                                        <div key={step.label}>
                                            {Math.abs(activeStep6 - index) <= 2 ? (
                                                <img className={classes.img} src={step.imgPath} alt={step.label} />
                                            ) : null}
                                        </div>
                                    ))}
                                </AutoPlaySwipeableViews>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <div id="cashback" className="cards-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2>Cum functioneaza Cashback-ul?</h2>
                            <p className="p-heading p-large">Pentru fiecare vanzare pe care noi o aducem unui magazin partener primim un comision, pe care il impartim cu tine.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">

                            <div className="card">
                                <img className="card-image" src="/images/design/touch-app.svg" alt="alternative" />
                                <div className="card-body">
                                    <h4 className="card-title">Click pe magazinul dorit</h4>
                                    <p>Intri in cont si apesi pe magazinul de la care vrei sa cumperi si selectezi CASHBACK.</p>
                                </div>
                            </div>

                            <div className="card">
                                <img className="card-image" src="/images/design/shop-chart.svg" alt="alternative" />
                                <div className="card-body">
                                    <h4 className="card-title">Cumperi la acelasi pret</h4>
                                    <p>Cumparaturile le faci direct pe site-ul partenerilor. Poti plati online cu cardul sau ramburs.</p>
                                </div>
                            </div>

                            <div className="card">
                                <img className="card-image" src="/images/design/cashback.svg" alt="alternative" />
                                <div className="card-body">
                                    <h4 className="card-title">Primesti banii in cont</h4>
                                    <p>In maxim 10 minute apar banii in Portofelul True. La 50 de lei ii poti retrage in contul tau bancar.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="tombola" className="cards-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2>Cum functioneaza Tombola?</h2>
                            <p className="p-heading p-large">Ai sansa sa castigi valoarea intreaga a comenzii tale. Citeste <a className="turquoise" href="https://docs.google.com/document/d/19Jj60dZrVOibN8N17X3_d38IgpOei0SNkogj5TJVmgg/edit?usp=sharing" rel="noopener noreferrer" target='_blank'>Regulamentul </a> aici.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">

                            <div className="card">
                                <img className="card-image" src="/images/design/touch-app.svg" alt="alternative" />
                                <div className="card-body">
                                    <h4 className="card-title">Click pe magazinul dorit</h4>
                                    <p>Intri in cont si apesi pe magazinul de la care vrei sa cumperi si selectezi TOMBOLA.</p>
                                </div>
                            </div>

                            <div className="card">
                                <img className="card-image" src="/images/design/group.svg" alt="alternative" />
                                <div className="card-body">
                                    <h4 className="card-title">Faci o comanda</h4>
                                    <p>Comisioanele de la comenzile participantilor se strang intr-un fond comun.</p>
                                </div>
                            </div>

                            <div className="card">
                                <img className="card-image" src="/images/design/winner.svg" alt="alternative" />
                                <div className="card-body">
                                    <h4 className="card-title">Castigi banii pe comanda</h4>
                                    <p>Daca valoarea comenzii tale se incadreaza in valoarea fondului, castigi! Afli rezultatul in 7-30 de zile.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="footer" className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="footer-col">
                                <h4>Despre TRUE</h4>
                                <p>Suntem pasionati de #pisici si #preturimici</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="footer-col middle">
                                <h4>Link-uri Importante</h4>
                                <ul className="list-unstyled li-space-lg">
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body">Cititi <a className="turquoise" href="https://docs.google.com/document/d/13VsdMoQlooTpyONu3XVgoPDvzgOc51bAUTIdtBHMgvM/edit?usp=sharing" rel="noopener noreferrer" target='_blank'>Termeni & Conditii</a>, <LinkRouter className="turquoise" to={PRIVACY}>Confidentialitate</LinkRouter></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><a className="turquoise" href="https://anpc.ro" target='_blank'>ANPC</a></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="footer-col last">
                                <h4>Social Media</h4>
                                <span className="fa-stack">
                                    <a href="https://www.facebook.com/recompensashoppingului/" rel="noopener noreferrer" target='_blank'>
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fab fa-facebook-f fa-stack-1x"></i>
                                    </a>
                                </span>
                                <span className="fa-stack">
                                    <a href="https://www.instagram.com/recompensashoppingului/" rel="noopener noreferrer" target='_blank'>
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fab fa-instagram fa-stack-1x"></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <p className="p-small">Copyright © TRUE 2019. SC KAIROCK PARTNERS SRL CUI: 34392456 J22/702/21.04.2015</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Landing;