import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    item: {
        height: '100%'
    },
    pagination: {
        marginTop: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    }
}));

export default useStyle;
