import { makeStyles } from "@material-ui/styles";


const useStyle = makeStyles(theme => ({
    media: {
        height: '150px',
    },
    content: {
        paddingBottom: '5px'
    },
    commission: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '15px',
        color: theme.palette.text.primary
    },
    description: {
        paddingBottom: '15px',
        color: theme.palette.text.secondary
    },
    learnMore: {
        marginLeft: 'auto'
    },
    chip: {
        marginRight: 5
    }
}))

export default useStyle;
