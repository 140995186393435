import React, { createContext, useEffect, useReducer } from 'react';

import { firebase, firestore } from '../firebase';

import { authReducer } from '../reducers/authReducer';
import { UPDATE_USER } from '../common/actionType';

export const AuthUserContext = createContext();

const initState = {
    user: null,
    profile: null,
    isLoaded: false,
    isNewUser: false,
    hasReferralCode: true,
    signInError: null,
    signUpError: null,
};

const AuthUserContextProvider = (props) => {
    const [auth, dispatch] = useReducer(authReducer, initState, () => {
        return {
            ...initState,
            user: firebase.auth().currentUser,
            isLoaded: firebase.auth().currentUser ? true : false
        }
    })

    useEffect(() => {
        const authListener = firebase.auth().onAuthStateChanged(authUser => {
            if (authUser) {
                firestore.collection('Users').doc(authUser.uid).onSnapshot(doc => {
                    if (doc.exists) {
                        dispatch({
                            type: UPDATE_USER,
                            authUser,
                            profile: doc.data(),
                            isLoaded: true
                        });
                    } else {
                        dispatch({
                            type: UPDATE_USER,
                            authUser,
                            isLoaded: false
                        });
                    }
                }, error => {
                    console.log(error.message)
                });
            } else {
                dispatch({
                    type: UPDATE_USER,
                    authUser,
                    isLoaded: true
                });
            }
        });
        return () => authListener();
    }, [])

    return (
        <AuthUserContext.Provider value={{ auth, dispatch }}>
            {props.children}
        </AuthUserContext.Provider>
    );
}

export default AuthUserContextProvider;