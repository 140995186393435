import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles(theme => ({
    item: {
        marginTop: theme.spacing(2.5)
    },
    logoImage: {
        width: '300px',
        height: '175px'
    },
    googleButton: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.secondary
    },
    facebookButton: {
        backgroundColor: "#3f51b5",
        borderColor: '#3f51b5',
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: '#25306C',
            borderColor: '#25306C',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#25306C',
            borderColor: '#25306C',
        },
        '&:focus': {
            backgroundColor: '#25306C',
            borderColor: '#25306C',
            boxShadow: 'none',
        },
    },
    sugestion: {
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(2),
        textAlign: 'center'
    },
    form: {
        maxWidth: '350px'
    },
    signInButton: {
        textAlign: 'center',
        marginTop: theme.spacing(1)
    },
    error: {
        color: theme.palette.error.main
    },
    signUp: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: theme.palette.text.secondary
    },
    signUpUrl: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
}));

export default useStyle;