import {
    PAYMENT_SUCCESS,
    SIGN_IN_ERROR,
    SIGN_IN_SUCCESS,
    SIGN_OUT_SUCCESS,
    SIGN_UP_ERROR,
    SIGN_UP_SUCCESS,
    UPDATE_USER,
    UPDATE_USER_STATUS
} from "../common/actionType";

export const authReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_USER:
            return {
                ...state,
                user: action.authUser,
                profile: action.profile,
                isLoaded: action.isLoaded,
            }
        case SIGN_IN_SUCCESS:
            console.log('Login success!');
            return {
                ...state,
                isNewUser: false,
                hasReferralCode: true,
                signInError: null
            }
        case SIGN_IN_ERROR:
            console.log('Error sign in: ' + action.error.message);
            return {
                ...state,
                signInError: action.error
            }
        case SIGN_UP_SUCCESS:
            console.log('Sign up success!');
            return {
                ...state,
                profile: action.profile,
                isNewUser: true,
                hasReferralCode: action.hasCode,
                signUpError: null
            }
        case SIGN_UP_ERROR:
            console.log('Error sign up: ' + action.error.message);
            return {
                ...state,
                signUpError: action.error
            }
        case UPDATE_USER_STATUS:
            return {
                ...state,
                isNewUser: action.isNewUser,
                hasReferralCode: action.hasCode
            }
        case SIGN_OUT_SUCCESS:
            console.log('Sign out success!')
            return {
                ...state,
                user: null,
                profile: null,
                isNewUser: false,
                hasReferralCode: true,
                signInError: null,
                signUpError: null
            }
        case PAYMENT_SUCCESS: {
            console.log('Payment success!')
            return {
                ...state,
                profile: action.profile
            }
        }
        default:
            return state
    }
}