import React, { createContext, useEffect, useReducer } from 'react';

import { firestore } from '../firebase';

import { ongReducer } from '../reducers/ongReducer';

import { FETCH_ONGS_SUCCESS, FETCH_ONGS_ERROR } from '../common/actionType';

export const OngContext = createContext();

const OngContextProvider = (props) => {
    const [ongs, dispatch] = useReducer(ongReducer, {
        keys: [],
        entries: {},
        error: null
    });

    useEffect(() => {
        firestore.collection('Ongs').orderBy('name').get().then(snapshot => {
            let entries = {};
            let keys = [];
            snapshot.forEach(doc => {
                keys.push(doc.id);
                entries[doc.id] = doc.data();
            });
            dispatch({
                type: FETCH_ONGS_SUCCESS,
                // page: vendors.page,
                keys: keys,
                entries: entries,
            });
        }).catch(error => {
            dispatch({
                type: FETCH_ONGS_ERROR,
                error: error
            })
        });
    }, []);

    return (
        <OngContext.Provider value={{ ongs, dispatch }}>
            {props.children}
        </OngContext.Provider>

    );
}

export default OngContextProvider;