import MuiButton from './MuiButton';
import MuiCardMedia from './MuiCardMedia';
import MuiChip from './MuiChip';
import MuiFab from './MuiFab';
import MuiIconButton from './MuiIconButton';
import MuiListItemIcon from './MuiListItemIcon';
import MuiSvgIcon from './MuiSvgIcon';
import MuiTab from './MuiTab';
import MuiTableCell from './MuiTableCell';
import MuiTableRow from './MuiTableRow';
import MuiTabs from './MuiTabs';

export default {
  MuiButton,
  MuiCardMedia,
  MuiChip,
  MuiFab,
  MuiIconButton,
  MuiListItemIcon,
  MuiSvgIcon,
  MuiTab,
  MuiTableCell,
  MuiTableRow,
  MuiTabs
};
