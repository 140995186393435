export const LANDING = '/';
export const ACCOUNT = '/account';
export const DASHBOARD = '/dashboard';
export const OFFERS = '/offers';
export const ONG = '/ong';
export const REFERRALS = '/referrals';
export const VENDORS = '/vendors';
export const PRODUCTS = '/products';
export const WALLET = '/wallet';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const PRIVACY = '/privacy';
