import React, { createContext, useEffect, useReducer } from 'react';

import { firestore } from '../firebase';

import { vendorReducer } from '../reducers/vendorReducer';

import { FETCH_VENDORS_SUCCESS, FETCH_VENDORS_ERROR } from '../common/actionType';

export const VendorContext = createContext();

const VendorContextProvider = (props) => {
    const [vendors, dispatch] = useReducer(vendorReducer, {
        keys: [],
        entries: {},
        display: [], // vendors that should be displayed.
        page: 0, // current page.
        error: null
        // pages: {}, // for each page keep the ids.
        // isLoaded: false
    });

    useEffect(() => {
        firestore.collection('Vendors').where('display', '==', true).orderBy('score', 'desc').orderBy('name').get().then(snapshot => {
            let entries = {};
            let keys = [];
            snapshot.forEach(doc => {
                keys.push(doc.id);
                entries[doc.id] = doc.data();
            });
            dispatch({
                type: FETCH_VENDORS_SUCCESS,
                // page: vendors.page,
                keys: keys,
                entries: entries,
            });
        }).catch(error => {
            dispatch({
                type: FETCH_VENDORS_ERROR,
                error: error
            })
        });
    }, []);

    // useEffect(() => {
    //     dispatch({
    //         type: VENDORS_
    //     })
    // }, [vendors.page])
    // TODO: in case of scaling.
    // const [ref, setRef] = useState(firestore.collection('Vendors').where("display", "==", true).orderBy("name").limit(VENDORS_PER_PAGE));

    // useEffect(() => {
    //     if (vendors.pages[vendors.page]) {
    //         // cache hit
    //         dispatch({
    //             type: CACHE_HIT_VENDORS
    //         });
    //     } else {
    //         ref.get().then(snapshot => {
    //             let entries = {};
    //             let keys = [];
    //             snapshot.forEach(doc => {
    //                 keys.push(doc.id);
    //                 entries[doc.id] = doc.data();
    //             });
    //             setRef(firestore.collection('Vendors').where("display", "==", true).orderBy("name").startAfter(snapshot.docs[snapshot.docs.length - 1]).limit(VENDORS_PER_PAGE))
    //             dispatch({
    //                 type: FETCH_VENDORS_SUCCESS,
    //                 page: vendors.page,
    //                 keys: keys,
    //                 entries: entries,
    //             });
    //         }).catch(error => {
    //             dispatch({
    //                 type: FETCH_VENDORS_ERROR,
    //                 error: error
    //             })
    //         });
    //     }
    // }, [vendors.page]);

    return (
        <VendorContext.Provider value={{ vendors, dispatch }}>
            {props.children}
        </VendorContext.Provider>

    );
}

export default VendorContextProvider;