import React, { useState, useContext } from 'react';

import { Link as LinkRouter } from 'react-router-dom';

import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    LinearProgress,
    TextField,
    Typography,
    FormHelperText
} from '@material-ui/core';

import useStyle from './style.js';
import { AuthUserContext, FirebaseContext, AnalyticsContext } from '../../../contexts/';
import { SIGN_UP_SUCCESS, SIGN_UP_ERROR } from '../../../common/actionType.js';
import { getCode, clearStorage } from '../../../common/util';
import { PRIVACY, LANDING } from '../../../common/routes.js';

const SignUp = (props) => {
    const { firebase } = useContext(FirebaseContext);
    const { analytics } = useContext(AnalyticsContext);
    const { auth, dispatch } = useContext(AuthUserContext);

    const [credentials, setCredentials] = useState({
        'firstName': '',
        'lastName': '',
        'email': '',
        'password': '',
        'confirmPassword': '',
        'code': getCode(),
        'terms': false,
        'newsletter': false,
    });
    const [errors, setErrors] = useState({
        'firstName': null,
        'lastName': null,
        'email': null,
        'password': null,
        'confirmPassword': null,
        'code': null,
        'terms': null
    })

    const [loading, setLoading] = useState(false);

    const classes = useStyle();

    const handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();

        if (!isTermsAndConditionsAccepted()) return;

        if (!isEmailValid() || !isPasswordValid() || !isConfirmPasswordValid() || !isNameValid('firstName') || !isNameValid('lastName')) return

        const profile = {
            firstName: credentials.firstName,
            lastName: credentials.lastName,
            newsletter: !credentials.newsletter
        }

        setLoading(true);
        if (!credentials.code) {
            firebase.auth().createUserWithEmailAndPassword(credentials.email, credentials.password).then((response) => {
                var createUser = firebase.functions().httpsCallable('createUser');
                createUser({
                    uid: response.user.uid,
                    profile: profile,
                }).then((result) => {
                    dispatch({
                        type: SIGN_UP_SUCCESS,
                        profile: profile,
                        hasCode: false
                    });
                    setLoading(false);
                });
            }).catch((error) => {
                setLoading(false);
                dispatch({ type: SIGN_UP_ERROR, error });
            });
        } else {
            var verifyCode = firebase.functions().httpsCallable('verifyCode');
            verifyCode({ code: credentials.code.toUpperCase() }).then((result) => {
                if (!result.data.userId) {
                    setErrors({ ...errors, code: 'Cod promo invalid.' });
                    setLoading(false);
                    return;
                }

                firebase.auth().createUserWithEmailAndPassword(credentials.email, credentials.password).then((response) => {
                    var createUser = firebase.functions().httpsCallable('createUser');
                    createUser({
                        uid: response.user.uid,
                        profile: profile,
                        referrer: result.data
                    }).then((result) => {
                        dispatch({
                            type: SIGN_UP_SUCCESS,
                            profile: profile,
                            hasCode: true
                        });
                        setLoading(false);
                        analytics.logEvent('sign_up');
                        clearStorage();
                    });
                }).catch((error) => {
                    setLoading(false);
                    dispatch({ type: SIGN_UP_ERROR, error });
                });
            }).catch((error) => {
                setLoading(false);
                console.log(error);
            })
        }
    }

    const handleChange = name => event => {
        setCredentials({
            ...credentials,
            [name]: event.target.value
        })
        setErrors({
            ...errors,
            [name]: null,
        })
    }

    const handleChangeBoolean = name => event => {
        setCredentials({
            ...credentials,
            [name]: event.target.checked
        })
        setErrors({
            ...errors,
            [name]: null,
        })
    }

    const isTermsAndConditionsAccepted = () => {
        if (!credentials.terms) {
            setErrors({ ...errors, terms: "Te rugam citeste si accepta Termeni si Conditii." });
            return false;
        }
        return true;
    }

    const isEmailValid = () => {
        if (credentials.email.length > 254) {
            setErrors({ ...errors, email: 'Limita este de maxim 254 caractere' });
            return false;
        }
        setErrors({ ...errors, email: null });
        return true;
    }

    const isNameValid = (type) => {
        if (credentials[type].length > 50) {
            setErrors({ ...errors, [type]: 'Limita este de maxim 50 caractere' });
            return false;
        }
        setErrors({ ...errors, [type]: null });
        return true;
    }

    const isPasswordValid = () => {
        if (credentials.password.length < 6) {
            setErrors({ ...errors, password: 'Limita este de minim 6 caractere' });
            return false;
        }

        if (credentials.password.length > 100) {
            setErrors({ ...errors, password: 'Limita este de maxim 100 caractere' });
            return false;
        }

        setErrors({ ...errors, password: null });
        return true;
    }

    const isConfirmPasswordValid = () => {
        if (credentials.password !== credentials.confirmPassword) {
            setErrors({ ...errors, confirmPassword: 'Parolele nu sunt identice' });
            return false;
        }
        setErrors({ ...errors, confirmPassword: null });
        return true;
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
        >
            <Grid
                item
                xs={12}
                className={classes.item}
            >
                <img
                    alt="True logo"
                    className={classes.logoImage}
                    src="/images/logo/true1.png"
                />
            </Grid>
            <Grid
                item
                xs={12}
            >
                <Typography
                    variant="h2"
                >
                    Creeaza cont nou
                  </Typography>
            </Grid>
            <form onSubmit={handleSubmit} className={classes.form}>
                <Grid
                    item
                    xs={12}
                    className={classes.item}
                >
                    <TextField
                        id="firstName"
                        label="Prenume"
                        type="text"
                        value={credentials.firstName}
                        onChange={handleChange('firstName')}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        required
                        error={errors.firstName ? true : false}
                        helperText={errors.firstName}
                    />

                    <TextField
                        id="lastName"
                        label="Nume"
                        type="text"
                        value={credentials.lastName}
                        onChange={handleChange('lastName')}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        required
                        error={errors.lastName ? true : false}
                        helperText={errors.lastName}
                    />

                    <TextField
                        id="email"
                        label="Adresa e-mail"
                        type="email"
                        value={credentials.email}
                        onChange={handleChange('email')}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        required
                        error={errors.email ? true : false}
                        helperText={errors.email}
                    />

                    <TextField
                        id="password"
                        label="Parola"
                        type="password"
                        value={credentials.password}
                        onChange={handleChange('password')}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        required
                        error={errors.password ? true : false}
                        helperText={errors.password}
                    />

                    <TextField
                        id="confirmPassword"
                        label="Confirma parola"
                        type="password"
                        value={credentials.confirmPassword}
                        onChange={handleChange('confirmPassword')}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        required
                        error={errors.confirmPassword ? true : false}
                        helperText={errors.confirmPassword}
                    />
                    <TextField
                        id="code"
                        label="Cod promo"
                        type="text"
                        value={credentials.code}
                        onChange={handleChange('code')}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        disabled={sessionStorage.getItem('code') ? true : false}
                        error={errors.code ? true : false}
                        helperText={errors.code ? errors.code : "Un cod promo iti da si mai multi bani inapoi"}
                    />
                    <FormControlLabel
                        className={classes.terms}
                        control={
                            <Checkbox
                                checked={credentials.terms}
                                onChange={handleChangeBoolean('terms')}
                                value={credentials.terms}
                            />
                        }
                        label={<div><span>Am citit si accept </span>
                            <a
                                className={classes.signInUrl}
                                href="https://docs.google.com/document/d/13VsdMoQlooTpyONu3XVgoPDvzgOc51bAUTIdtBHMgvM/edit?usp=sharing" rel="noopener noreferrer" target='_blank'>
                                Termeni & Conditii
                            </a><span> si </span>
                            <LinkRouter
                                className={classes.signInUrl}
                                to={PRIVACY} rel="noopener noreferrer" target='_blank'>
                                Politica de Confidentialitate
                            </LinkRouter>
                        </div>}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={credentials.newsletter}
                                onChange={handleChangeBoolean('newsletter')}
                                value={credentials.newsletter}
                            />
                        }
                        label={<div><span>Aleg sa NU PRIMESC bonusuri, discount-uri si promotii de la TRUE pe email.</span></div>}
                    />
                    {errors.terms ? <FormHelperText error>Te rugam citeste si accepta Termenii & Conditiile si Politica de Confidentialitate</FormHelperText> : null}
                    {loading ? <LinearProgress /> : null}
                </Grid>
                <Grid
                    item
                    xs={12}
                    className={classes.signUpButton}
                >
                    {auth.signUpError && <Typography variant="body1" gutterBottom className={classes.error}>{auth.signUpError.message}</Typography>}
                    <Button type="submit" variant="contained" color="primary" disabled={loading}>
                        Inscrie-te
                    </Button>
                    <Typography
                        className={classes.signIn}
                        variant="body1"
                    >
                        Ai cont deja?{' '}
                        <LinkRouter
                            className={classes.signInUrl}
                            to={LANDING}
                        >
                            Intra in cont
                    </LinkRouter>
                    </Typography>
                </Grid>
            </form>
        </Grid>
    );
}

export default SignUp;