export const getCode = () => {
    if (sessionStorage.getItem('code')) return sessionStorage.getItem('code');

    if (!localStorage.getItem('code')) return '';
    // keep code for 7 days maximum
    if (Math.floor(Date.now() / 1000) - localStorage.getItem('time') > 604800) {
        localStorage.removeItem('code');
        localStorage.removeItem('time');
        return '';
    }

    return localStorage.getItem('code');
}

export const clearStorage = () => {
    sessionStorage.removeItem('code');
    localStorage.removeItem('code');
    localStorage.removeItem('time');
}