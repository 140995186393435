import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles(theme => ({
    item: {
        marginTop: theme.spacing(2.5)
    },
    logoImage: {
        width: '300px',
        height: '175px'
    },
    form: {
        maxWidth: '350px'
    },
    signUpButton: {
        textAlign: 'center',
        marginTop: theme.spacing(1)
    },
    error: {
        color: theme.palette.error.main
    },
    signIn: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
        color: theme.palette.text.secondary
    },
    signInUrl: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
    terms: {
        paddingTop: '10px'
    }
}));

export default useStyle;