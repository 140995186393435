import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { LANDING} from './common/routes';
import { Landing } from './views';


const RoutesNonAuth = () => {
    return (
        <Switch>
            <Route
                component={Landing}
                exact
                path={LANDING}
            />
            <Redirect
                to={LANDING}
            />
        </Switch>
    );
}

const RoutesLanding = () => {
    return  <RoutesNonAuth />;
}

export default RoutesLanding;
