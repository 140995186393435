import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const config = {
  apiKey: "AIzaSyBHzZj36nzjiIhH9IJfbZRa4J43n27QyXY",
  authDomain: "daenerys-2019.firebaseapp.com",
  databaseURL: "https://daenerys-2019.firebaseio.com",
  projectId: "daenerys-2019",
  storageBucket: "daenerys-2019.appspot.com",
  messagingSenderId: "1015708398165",
  appId: "1:1015708398165:web:71327608fd5c4498",
  measurementId: "G-ETH3GF3S9N"
};

firebase.initializeApp(config);

export const firestore = firebase.firestore();

export const analytics = firebase.analytics();

export default firebase;