import { FETCH_ONGS_ERROR, FETCH_ONGS_SUCCESS } from "../common/actionType";

export const ongReducer = (state, action) => {
  switch (action.type) {
    case FETCH_ONGS_SUCCESS:
      return {
        ...state,
        keys: action.keys,
        entries: {
          ...state.entries,
          ...action.entries,
        },
        error: null
      }
    case FETCH_ONGS_ERROR:
      console.log('Error fetching ONGs ' + action.error.message)
      return {
        ...state,
        error: action.error,
        entries: {},
      }
    default:
      return state;
  }
} 