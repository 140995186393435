import { FETCH_WALLET_SUCCESS, FETCH_WALLET_ERROR, UPDATE_WALLET, PAYMENT_SUCCESS, DONATE_SUCCESS } from "../common/actionType";

export const walletReducer = (state, action) => {
    switch (action.type) {
        case FETCH_WALLET_SUCCESS:
            return {
                ...state,
                entry: action.entry,
                error: null
            }
        case FETCH_WALLET_ERROR:
            return {
                ...state,
                error: action.error,
                entry: []
            }
        case UPDATE_WALLET:
            console.log('Wallet update');
            return {
                ...state,
                entry: {
                    ...state.entry,
                    rate: action.rate,
                    referral: action.referral
                }
            }
        case PAYMENT_SUCCESS: {
            console.log('Payment success!')
            return {
                ...state,
                entry: {
                    ...state.entry,
                    commission: {
                        ...state.entry.commission,
                        approved: {
                            personal: {
                                count: 0,
                                value: 0
                            },
                            referrals: {
                                count: 0,
                                value: 0
                            }
                        }
                    },
                    paymentRequest: action.money
                }
            }
        }
        case DONATE_SUCCESS: {
            console.log('Donate success!')
            return {
                ...state,
                entry: {
                    ...state.entry,
                    commission: {
                        ...state.entry.commission,
                        approved: {
                            ...state.entry.commission.approved,
                            personal: {
                                count: state.entry.commission.approved.personal.count - 1,
                                value: state.entry.commission.approved.personal.value - action.commission.amount
                            },
                        },
                        paid: {
                            ...state.entry.commission.paid,
                            personal: {
                                count: state.entry.commission.paid.personal.count + 1,
                                value: state.entry.commission.paid.personal.value + action.commission.amount
                            },
                        }
                    },
                    paymentRequest: action.money
                }
            }
        }
        default:
            return state;
    }
} 