// Account actions.
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';
// Vendor actions.
export const FETCH_VENDORS_ERROR = 'FETCH_VENDORS_ERROR';
export const FETCH_VENDORS_SUCCESS = 'FETCH_VENDORS_SUCCESS';
export const FETCH_UPDATE_PAGE = 'FETCH_UPDATE_PAGE';
// Wallet actions
export const FETCH_WALLET_ERROR = 'FETCH_WALLET_ERROR';
export const FETCH_WALLET_SUCCESS = 'FETCH_WALLET_SUCCESS';
export const FETCH_COMMISSIONS_ERROR = 'FETCH_COMMISSIONS_ERROR';
export const FETCH_COMMISSIONS_SUCCESS = 'FETCH_COMMISSIONS_SUCCESS';
export const UPDATE_WALLET = 'UPDATE_WALLET';
// Payment
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
// ONG
export const FETCH_ONGS_ERROR = 'FETCH_ONGS_ERROR';
export const FETCH_ONGS_SUCCESS = 'FETCH_ONGS_SUCCESS';
export const DONATE_SUCCESS = 'DONATE_SUCCESS';
// Offers
export const FETCH_OFFERS_ERROR = 'FETCH_OFFERS_ERROR';
export const FETCH_OFFERS_SUCCESS = 'FETCH_OFFERS_SUCCESS';

