import { makeStyles } from "@material-ui/styles";


const useStyle = makeStyles(theme => ({
    searchField: {
        backgroundColor: theme.palette.common.white,
    },
    formControl: {
        backgroundColor: theme.palette.common.white,
        marginTop: theme.spacing(2),
    },
}))

export default useStyle;
