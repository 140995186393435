import { makeStyles } from "@material-ui/styles";


const useStyle = makeStyles(theme => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 650,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    chip: {
        margin: theme.spacing(1),
    },
    pending: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.common.white
    },
    rejected: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.common.white
    },
    approved: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.common.white
    },
    paid: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    cell: {
        color: theme.palette.primary.main
    }
}))

export default useStyle;
