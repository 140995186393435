import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    noCommissions: {
        marginTop: '20px'
    },
    pending: {
        backgroundColor: theme.palette.warning.main
    },
    rejected: {
        backgroundColor: theme.palette.error.main
    },
    approved: {
        backgroundColor: theme.palette.success.main
    },
    paid: {
        backgroundColor: theme.palette.common.white
    },
    actions: {
        marginTop: '10px'
    }
}));

export default useStyle;