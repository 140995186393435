import { FETCH_COMMISSIONS_SUCCESS, FETCH_COMMISSIONS_ERROR } from "../common/actionType";

export const commissionReducer = (state, action) => {
    switch (action.type) {
        case FETCH_COMMISSIONS_SUCCESS:
            return {
                ...state,
                entries: action.entries,
                error: null
            }
        case FETCH_COMMISSIONS_ERROR:
            return {
                ...state,
                error: action.error,
                entries: []
            }
        default:
            return state;
    }
} 