import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    item: {
        height: '100%'
    }
}));

export default useStyle;
