import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles(theme => ({
    info: {
        display: 'flex'
    },
    infoText: {
        paddingLeft: 7
    },
    paymentButton: {
        color: theme.palette.success.main
    },
    statusOk: {
        color: theme.palette.success.main
    },
    statusBad: {
        color: theme.palette.error.main
    }
}));

export default useStyle;