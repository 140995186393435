import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.border}`,
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        height: '64px',
        zIndex: theme.zIndex.appBar
    },
    toolbar: {
        minHeight: 'auto',
        width: '100%'
    },
    title: {
        marginLeft: theme.spacing(),
        flexGrow: '1'
    },
    menuButton: {
        marginLeft: '-4px'
    },
    signOutButton: {
    },
    tutorialButton: {
        marginRight: '10px'
    }
}));

export default useStyle;