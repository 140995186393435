import React, { useContext } from 'react';

import { AuthUserContext } from '../../contexts';

import Routes from '../../Routes';
import RoutesLanding from '../../RoutesLanding';

const Spinner = () => {
    return (<div className="spinner-wrapper">
        <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
        </div>
    </div>)
}
const Main = () => {
    const { auth } = useContext(AuthUserContext)
    return (
        !auth.isLoaded ? <Spinner /> : <RoutesLanding />
    );
}

export default Main;