import { FETCH_VENDORS_SUCCESS, FETCH_VENDORS_ERROR, FETCH_UPDATE_PAGE } from "../common/actionType";

export const vendorReducer = (state, action) => {
  switch (action.type) {
    case FETCH_VENDORS_SUCCESS:
      return {
        ...state,
        keys: action.keys,
        entries: {
          ...state.entries,
          ...action.entries,
        },
        error: null
      }
    case FETCH_VENDORS_ERROR:
      console.log('Error fetching vendors ' + action.error.message)
      return {
        ...state,
        error: action.error,
        page: 0,
        pages: {},
        entries: {},
      }
    case FETCH_UPDATE_PAGE:
      return {
        ...state,
        page: action.page
      }
    default:
      return state;
  }
} 