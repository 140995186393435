import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    noInvite: {
        marginTop: '10px'
    },
    linkRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButtonLink: {
        color: '##546e7a',
        padding: 10
    },
    iconButton: {
        padding: 10,
    },
    stats: {
        color: theme.palette.primary.main
    }
}));

export default useStyle;
