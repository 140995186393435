import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        height: '100%'
    },
    rootDark: {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
    },
    avatar: {
        width: '3rem',
        height: '3rem',
    },
    title: {
        color: theme.palette.text.secondary,
        fontWeight: 700
    },
    titleDark: {
        color: theme.palette.common.white,
        fontWeight: 700
    },
    value: {
        marginTop: theme.spacing(1)
    },
    valueDark: {
        marginTop: theme.spacing(1),
        color: theme.palette.common.white
    },
    amount: {
        textAlign: 'center'
    },
    icon: {
        color: theme.palette.common.white,
        fontSize: '2rem',
        height: '2rem',
        width: '2rem'
    },
    iconDark: {
        color: theme.palette.primary.main,
        fontSize: '2rem',
        height: '2rem',
        width: '2rem'
    },
    stats: {
        display: 'flex',
        alignItems: 'center',
    },
    personIcon: {
        color: theme.palette.text.secondary
    },
    personText: {
        marginLeft: theme.spacing(1),
        color: theme.palette.text.secondary,
        fontWeight: 500
    },
    groupIcon: {
        marginLeft: 'auto',
        color: theme.palette.text.secondary
    },
    groupText: {
        marginLeft: theme.spacing(1),
        color: theme.palette.text.secondary,
        fontWeight: 500
    },
    dark: {
        color: theme.palette.common.white,
    }
}))

export default useStyle;
