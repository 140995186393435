import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';

import classNames from 'classnames';

import {
    Avatar,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@material-ui/core';

import {
    // DashboardOutlined as DashboardIcon,
    // CardGiftcardOutlined as CardGiftIcon,
    PeopleOutlined as PeopleIcon,
    ShoppingBasketOutlined as ShoppingBasketIcon,
    AccountBalanceWalletOutlined as AccountBalanceWalletIcon,
    // AccountBoxOutlined as AccountBoxIcon,
} from '@material-ui/icons';

import useStyle from './style';

import {
    // ACCOUNT,
    // DASHBOARD,
    LANDING,
    // ONG,
    REFERRALS,
    // PRODUCTS,
    VENDORS,
    WALLET,
    OFFERS
} from '../../../../common/routes';
import { AuthUserContext, WalletContext } from '../../../../contexts';

// TODO: fix this when the issue is closed.
// ForwardRef warning. The usage of React.forwardRef will no longer be required for react-router-dom v6
// see https://github.com/ReactTraining/react-router/issues/6056
const ForwardNavLink = React.forwardRef((props, ref) => (
    <NavLink {...props} innerRef={ref} />
));

const Sidebar = (props) => {
    const { auth } = useContext(AuthUserContext);
    const { wallet } = useContext(WalletContext);

    const classes = useStyle()

    const { className } = props;
    return (
        <nav className={classNames(classes.root, className)}>
            <div className={classes.logoWrapper}>
                <Link
                    className={classes.logoLink}
                    to={LANDING}
                >
                    <img
                        alt="True logo"
                        className={classes.logoImage}
                        src="/images/logo/true1.png"
                    />
                </Link>
            </div>
            <Divider className={classes.logoDivider} />
            <div className={classes.profile}>
                <Link to={VENDORS}>
                    <Avatar
                        className={classes.avatar}
                    >{auth.profile.firstName.slice(0, 1).toUpperCase() + auth.profile.lastName.slice(0, 1).toUpperCase()}</Avatar>
                </Link>
                <Typography variant="h6" className={classes.nameText}>{auth.profile.firstName + " " + auth.profile.lastName}</Typography>
            </div>
            <Divider className={classes.profileDivider} />
            <List
                component="div"
                disablePadding
            >
                {/* <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={ForwardNavLink}
                    to={DASHBOARD}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText
                        classes={{ primary: classes.listItemText }}
                        primary="Panou"
                    />
                </ListItem> */}
                {/* <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={ForwardNavLink}
                    to={PRODUCTS}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <ShoppingBasketIcon />
                    </ListItemIcon>
                    <ListItemText
                        classes={{ primary: classes.listItemText }}
                        primary="Produse"
                    />
                </ListItem> */}
                <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={ForwardNavLink}
                    to={VENDORS}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <ShoppingBasketIcon />
                    </ListItemIcon>
                    <ListItemText
                        classes={{ primary: classes.listItemText }}
                        primary="Magazine"
                    />
                </ListItem>
                <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={ForwardNavLink}
                    to={OFFERS}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <ShoppingBasketIcon />
                    </ListItemIcon>
                    <ListItemText
                        classes={{ primary: classes.listItemText }}
                        primary="Oferte"
                    />
                </ListItem>
                <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={ForwardNavLink}
                    to={WALLET}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText
                        classes={{ primary: classes.listItemText }}
                        primary="Portofel"
                    />
                </ListItem>
                {/* <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={ForwardNavLink}
                    to={ONG}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <CardGiftIcon />
                    </ListItemIcon>
                    <ListItemText
                        classes={{ primary: classes.listItemText }}
                        primary="ONG"
                    />
                </ListItem> */}
                {wallet.entry && wallet.entry.type ?
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={ForwardNavLink}
                        to={REFERRALS}
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary="Invitatii"
                        />
                    </ListItem>
                    : null}
                {/* <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={ForwardNavLink}
                    to={ACCOUNT}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText
                        classes={{ primary: classes.listItemText }}
                        primary="Cont"
                    />
                </ListItem> */}
            </List>
        </nav>
    );
}

export default Sidebar;