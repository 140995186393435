import React, { createContext, useEffect, useReducer } from 'react';

import { firestore } from '../firebase';

import { offerReducer } from '../reducers/offerReducer';

import { FETCH_OFFERS_SUCCESS, FETCH_OFFERS_ERROR } from '../common/actionType';

export const OfferContext = createContext();

const OfferContextProvider = (props) => {
    const [offers, dispatch] = useReducer(offerReducer, {
        keys: [],
        entries: {},
        display: [],
        page: 0,
        error: null
    });

    useEffect(() => {
        firestore.collection('Offers').where('display', '==', true).orderBy('end').get().then(snapshot => {
            let entries = {};
            let keys = [];
            snapshot.forEach(doc => {
                keys.push(doc.id);
                entries[doc.id] = doc.data();
            });
            dispatch({
                type: FETCH_OFFERS_SUCCESS,
                keys: keys,
                entries: entries,
            });
        }).catch(error => {
            dispatch({
                type: FETCH_OFFERS_ERROR,
                error: error
            })
        });
    }, []);

    return (
        <OfferContext.Provider value={{ offers, dispatch }}>
            {props.children}
        </OfferContext.Provider>

    );
}

export default OfferContextProvider;