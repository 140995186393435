import React, { useState, useContext, useEffect } from 'react';

import { Link, useParams } from 'react-router-dom';

import {
    Button,
    Grid,
    SvgIcon,
    TextField,
    Typography,
    LinearProgress
} from '@material-ui/core';

import { AuthUserContext, FirebaseContext, AnalyticsContext } from '../../../contexts/';

import { SIGN_IN_SUCCESS, SIGN_IN_ERROR, SIGN_UP_ERROR, SIGN_UP_SUCCESS } from '../../../common/actionType.js';

import useStyle from './style.js';
import { getCode, clearStorage } from '../../../common/util';
import { REGISTER } from '../../../common/routes';

const SignIn = (props) => {
    const { firebase } = useContext(FirebaseContext);
    const { analytics } = useContext(AnalyticsContext);
    const { auth, dispatch } = useContext(AuthUserContext);

    const { code } = useParams();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (code) {
            localStorage.setItem('code', code);
            localStorage.setItem('time', Math.floor(Date.now() / 1000));
            sessionStorage.setItem('code', code);
        }
    }, [code])

    const [credentials, setCredentials] = useState({
        'email': '',
        'password': ''
    });
    const [errors, setErrors] = useState({
        'email': null,
        'password': null
    })
    const classes = useStyle();

    const handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();

        if (!isEmailValid() || !isPasswordValid()) return

        setLoading(true);
        firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password).then((response) => {
            dispatch({ type: SIGN_IN_SUCCESS });
            analytics.logEvent('login');
            setLoading(false);
        }).catch((error) => {
            dispatch({ type: SIGN_IN_ERROR, error });
            setLoading(false);
        });
    }

    const signInWithFacebook = event => {
        var provider = new firebase.auth.FacebookAuthProvider();
        provider.addScope('public_profile');
        // provider.addScope('last_name');
        firebase.auth().signInWithPopup(provider).then((response) => {
            if (response.additionalUserInfo.isNewUser) {
                const profile = {
                    firstName: response.additionalUserInfo.profile.first_name,
                    lastName: response.additionalUserInfo.profile.last_name,
                    newsletter: true
                }
                const code = getCode();
                var createUser = firebase.functions().httpsCallable('createUser');
                createUser({
                    uid: response.user.uid,
                    profile: profile,
                    code: code
                }).then((result) => {
                    dispatch({
                        type: SIGN_UP_SUCCESS,
                        profile: profile,
                        hasCode: code && code !== '' ? true : false
                    });
                    analytics.logEvent('sign_up');
                    clearStorage();
                }).catch(error => {
                    console.log("Error profile created: " + error.message);
                    dispatch({ type: SIGN_UP_ERROR, error });
                });
            } else {
                dispatch({ type: SIGN_IN_SUCCESS });
                analytics.logEvent('login');
            }
        }).catch(function (error) {
            dispatch({ type: SIGN_IN_ERROR, error });
        });
    }

    const signInWithGoogle = event => {
        var provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/userinfo.email');
        provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
        firebase.auth().signInWithPopup(provider).then((response) => {
            if (response.additionalUserInfo.isNewUser) {
                const profile = {
                    firstName: response.additionalUserInfo.profile.given_name,
                    lastName: response.additionalUserInfo.profile.family_name,
                    newsletter: true
                }
                const code = getCode();
                var createUser = firebase.functions().httpsCallable('createUser');
                createUser({
                    uid: response.user.uid,
                    profile: profile,
                    code: code
                }).then((result) => {
                    dispatch({
                        type: SIGN_UP_SUCCESS,
                        profile: profile,
                        hasCode: code && code !== '' ? true : false
                    });
                    analytics.logEvent('sign_up');
                    clearStorage();
                }).catch(error => {
                    console.log("Error profile created: " + error.message);
                    dispatch({ type: SIGN_UP_ERROR, error });
                });
            } else {
                dispatch({ type: SIGN_IN_SUCCESS });
                analytics.logEvent('login');
            }
        }).catch(function (error) {
            dispatch({ type: SIGN_IN_ERROR, error });
        });
    }

    const handleChange = name => event => {
        setCredentials({
            ...credentials,
            [name]: event.target.value
        })
    }

    const isEmailValid = () => {
        if (credentials.email.length > 254) {
            setErrors({ ...errors, email: 'Must have at most 254 characters' });
            return false;
        }
        setErrors({ ...errors, email: null });
        return true;
    }

    const isPasswordValid = () => {
        if (credentials.password.length < 6) {
            setErrors({ ...errors, password: 'Must contain at least 6 characters' });
            return false;
        }

        if (credentials.password.length > 100) {
            setErrors({ ...errors, password: 'Must have at most 100 characters' });
            return false;
        }

        setErrors({ ...errors, password: null });
        return true;
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
        >
            <Grid
                item
                xs={12}
                className={classes.item}
            >
                <img
                    alt="True logo"
                    className={classes.logoImage}
                    src="/images/logo/true1.png"
                />
            </Grid>
            <Grid item xs={12}>
                <Button onClick={signInWithGoogle} variant="outlined" className={classes.googleButton}>
                    <SvgIcon>
                        <path fill='none' d="M0 0h18v18H0z" />
                        <path fill="#EA4335" d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z" />
                        <path fill="#4285F4" d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z" />
                        <path fill="#FBBC05" d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z" />
                        <path fill="#34A853" d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z" />
                    </SvgIcon>
                    Logare cu Google
                </Button>
            </Grid>
            <Grid
                item
                xs={12}
                className={classes.item}
            >
                <Button onClick={signInWithFacebook} color="primary" variant="outlined" className={classes.facebookButton}>
                    <SvgIcon>
                        <path d="M9.53144612,22.005 L9.53144612,13.0552149 L6.44166667,13.0552149 L6.44166667,9.49875 L9.53144612,9.49875 L9.53144612,6.68484375 C9.53144612,5.19972656 9.95946769,4.04680661 10.8155103,3.22608401 C11.6715529,2.4053613 12.808485,1.995 14.2263057,1.995 C15.3766134,1.995 16.3129099,2.04710915 17.0351961,2.15132812 L17.0351961,5.3169726 L15.1090998,5.3169726 C14.3868137,5.3169726 13.8919142,5.47330073 13.6244006,5.78595698 C13.4103902,6.04650407 13.3033846,6.46337874 13.3033846,7.03658198 L13.3033846,9.49875 L16.71418,9.49875 L16.2326559,13.0552149 L13.3033846,13.0552149 L13.3033846,22.005 L9.53144612,22.005 Z" />
                    </SvgIcon>
                    Logare cu Facebook
                </Button>
                <Typography
                    className={classes.sugestion}
                    variant="body1"
                >
                    sau cu adresa email
                  </Typography>
            </Grid>
            <form onSubmit={handleSubmit} className={classes.form}>
                <Grid
                    item
                    xs={12}
                    className={classes.item}
                >
                    <TextField
                        id="email"
                        label="Adresa email"
                        type="email"
                        value={credentials.email}
                        onChange={handleChange('email')}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        required
                        error={errors.email ? true : false}
                        helperText={errors.email}
                    />

                    <TextField
                        id="password"
                        label="Parola"
                        type="password"
                        value={credentials.password}
                        onChange={handleChange('password')}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        required
                        error={errors.password ? true : false}
                        helperText={errors.password}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    className={classes.signInButton}
                >
                    {auth.signInError && <Typography variant="body1" gutterBottom className={classes.error}>{auth.signInError.message}</Typography>}
                    {loading ? <LinearProgress /> : null}
                    <Button type="submit" variant="contained" color="primary" disabled={loading}>
                        Logare
                </Button>
                </Grid>
            </form>
            <Typography
                className={classes.signUp}
                variant="body1"
            >
                Nu ai cont?{' '}
                <Link
                    className={classes.signUpUrl}
                    to={REGISTER}
                >
                    Inscriere
                    </Link>
            </Typography>
        </Grid>
    );
}

export default SignIn;