import { FETCH_OFFERS_SUCCESS, FETCH_OFFERS_ERROR } from "../common/actionType";

export const offerReducer = (state, action) => {
  switch (action.type) {
    case FETCH_OFFERS_SUCCESS:
      return {
        ...state,
        keys: action.keys,
        entries: {
          ...state.entries,
          ...action.entries,
        },
        error: null
      }
    case FETCH_OFFERS_ERROR:
      console.log('Error fetching offers ' + action.error.message)
      return {
        ...state,
        error: action.error,
        page: 0,
        pages: {},
        entries: {},
      }
    default:
      return state;
  }
} 