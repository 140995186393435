import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
  common: {
    black,
    white
  },
  primary: {
    contrastText: white,
    main: colors.blue[500],
    light: colors.blue[50],
    dark: colors.blue[900]
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
  },
  background: {
    default: '#F8FAFC',
    paper: white
  },
  border: '#DFE3E8',
  divider: '#DFE3E8'
};
