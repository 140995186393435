import React, { createContext, useState } from 'react';

import { firebase as fb } from '../firebase';

export const FirebaseContext = createContext();

const FirebaseContextProvider = (props) => {
    const [firebase] = useState(fb);

    return (
        <FirebaseContext.Provider value={{ firebase }}>
            {props.children}
        </FirebaseContext.Provider>
    );
}

export default FirebaseContextProvider;