import { makeStyles } from "@material-ui/styles";


const useStyle = makeStyles(theme => ({
    media: {
        height: '150px',
    },
    content: {
        paddingBottom: '5px'
    },
    commission: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '15px',
        color: theme.palette.text.primary
    },
    description: {
        paddingBottom: '15px',
        height: '160px',
        color: theme.palette.text.secondary
    },
    learnMore: {
        marginLeft: 'auto'
    },
    chip: {
        marginRight: 5
    },
    shopName: {
        fontSize: '24px'
    },
    shopButton: {
        color: theme.palette.common.white
    },
    shopHint: {
        marginTop: '10px'
    },
    newFeature: {
        color: theme.palette.success.main
    }
}))

export default useStyle;
