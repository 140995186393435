import React, { createContext, useEffect, useReducer } from 'react';

import { firebase, firestore } from '../firebase';

import { commissionReducer } from '../reducers/commissionReducer';

import { FETCH_COMMISSIONS_ERROR, FETCH_COMMISSIONS_SUCCESS } from '../common/actionType';

export const CommissionContext = createContext();

const CommissionContextProvider = (props) => {
    const [commissions, dispatch] = useReducer(commissionReducer, {
        entries: [],
        error: null
    });

    useEffect(() => {
        const authListener = firebase.auth().onAuthStateChanged(authUser => {
            if (authUser) {
                firestore.collection("Commissions").where("userId", "==", authUser.uid).onSnapshot(snapshot => {
                    let entries = [];
                    snapshot.forEach(doc => {
                        entries.push({ ...doc.data(), id: doc.id });
                    });
                    dispatch({
                        type: FETCH_COMMISSIONS_SUCCESS,
                        entries: entries,
                    });
                }, error => {
                    dispatch({
                            type: FETCH_COMMISSIONS_ERROR,
                            error: error,
                        });
                });
            }
        });
        return () => authListener();
    }, []);

    return (
        <CommissionContext.Provider value={{ commissions, dispatch }}>
            {props.children}
        </CommissionContext.Provider>

    );
}

export default CommissionContextProvider;