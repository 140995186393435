import React, { createContext, useState } from 'react';

import { analytics as an} from '../firebase';

export const AnalyticsContext = createContext();

const AnalyticsContextProvider = (props) => {
    const [analytics] = useState(an);

    return (
        <AnalyticsContext.Provider value={{ analytics }}>
            {props.children}
        </AnalyticsContext.Provider>
    );
}

export default AnalyticsContextProvider;